import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchConfig() {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/page-setups/payout-list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPayoutMethodDetailsConfig(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/processing/page-setups/payout-methods/${queryParams.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAvailableCountriesConfig(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/page-setups/payouts/add/available-countries', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAvailablePayoutMethodsConfig(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/page-setups/payouts/add/available-payout-methods', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/payouts', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    export(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/payouts/export', {
            params: queryParams,
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/vnd.ms-excel',
              Accept: 'application/vnd.ms-excel',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPayout(ctx, model) {
      return new Promise((resolve, reject) => {
        axios
          .post('/processing/payouts', model)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
